








































































































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  props: ["post"],
  data: () => ({
    type: [] as any,
    students: [] as any,
    directions: [
      "默认方向",
      "语音增强",
      "语音识别",
      "语音合成",
      "声纹识别",
      "多模态交互",
    ],
    types:{
      "doctor":"博士研究生",
      "postgraduate":"硕士研究生",
      "undergraduates":"本科",
      "graduated":"毕业学生",
      "visitor":"访问学者"
    }
  }),
  mounted: function () {
    // 获取当前学生类型
    this.type = this.$route.name? (this.types as any)[this.$route.name] : "博士研究生";
    this.getStudentList();
    console.log(this.type)
  },
  methods: {
    getStudentList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list-need", {
          collection: "staff",
          need: {
            type: this.type,
          },
        })
        .then((response) => {
          console.log("get response");
          this.students = response.data;
          for (var i = 0; i < this.students.length; i++) {
            if (this.students[i].imagelist.length !== 0) {
              this.students[i].photo =
                Vue.prototype.serverAddr +
                "/" +
                this.students[i].imagelist[0];
            } else {
              this.students[i].photo = null;
            }
          }
          var new_students = this.students.slice(0)
          this.students.sort(function(a: any, b: any) {
            console.log(parseInt(a.grade))
            return parseInt(a.grade) < parseInt(b.grade) ? -1 : 1
          })
          console.log(this.students)
          console.log(new_students)
        });
    },
    gotoStudent(engName: string, type: string) {
      this.$router.push({
        path: `/staff/${this.$route.name}/${engName}`,
      });
    },
  },
});
